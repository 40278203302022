import React from "react"
import Header from "../new-home/header"
import LandingPageFooter from "../landing-page-footer"
import Footer from "../new-home/footer"
import SEO from "../seo"
import { GlobalAuthContext } from "../../context/authContext"
import { navigate } from "gatsby"

export default function NewPageLayout({
  isHomePage,
  children,
  isCandidate,
  title,
  description,
  seoImage,
}) {
  const { state } = React.useContext(GlobalAuthContext)

  React.useEffect(() => {
    // console.log(state.isAuthenticated)
    if (state.isAuthenticated) {
      if (state?.user?.candidate?.id) {
        navigate("/candidate/profile/dashboard")
      } else {
        navigate("/company/profile/dashboard")
      }
    }
  }, [state.isAuthenticated])
  return (
    <>
      <Header isCandidate={isCandidate} isHomePage={isHomePage} />
      <SEO
        title={title || "WDN - Search Connect Work"}
        description={description}
        seoImage={seoImage}
      />
      {children}
      {isHomePage ? <LandingPageFooter classes={"d-lg-none"} /> : <Footer />}
    </>
  )
}
