import React from "react";

const PrivacyPolicyContents = () => {
    return (
        <div className="front-page"><section className="breadcrumb-section undefined"><nav aria-label="breadcrumb"><ol className="breadcrumb"><div className="d-flex container"><li className="breadcrumb-item"><a href="/"><span>Home</span></a></li><li className="breadcrumb-item active">Privacy Policy</li></div></ol></nav></section><section className="title-description"><div className="container"><div className="text-left"><h2 className="mb-4">Privacy Policy</h2><div><p>Welcome to the platform of Workers’ Directory Nepal. We respect the privacy of our valued customers and ought to protect your personal information. Find the detailed information below.</p>
            <p>This privacy policy explains all the information about the mediums we use to collect the data, disclose, and use your personal information. It also explains what we do to secure your personal information.</p>
            <p>Your personal information is important to you, and we value your privacy. We keep your data when necessary and relevant to our transactions.</p>
            <h3>What kind of data do we collect?</h3>
            <p>We collect different pieces of information from you to provide you the required products. We collect data from your profile and the Companies you have shortlisted. Here are the types of data we collect from you.</p>
            <ul>
                <li><strong>Sign Up details: </strong>We accumulate the information you provide while using our product, including signup details. We record your information such as your name, mobile number, ID, Password, and every other detail you fill up while shining up on our platform.</li>
                <li><strong>Content created or shared on the platform: </strong>We keep a record of any content you create or share on our platform. <i>Note: The content includes only the job integration that is currently under development. Our platform does not allow you to create or share any other kind of content.</i></li>
                <li><strong>Transactions: </strong>We keep the information about the transactions you make on our products, including your purchase information. For example: If a Company wants to purchase information about any Candidate, it has to pay a certain amount. We record the details of such transactions.</li>
                <li><strong>Messages or Communications: </strong>We acquire information about the messages you send to or receive from any Companies on our product. For example, if you get a message request from any Company, we record your response. <i>Note: We do not have this feature for now, but we will add this feature soon.</i></li>
                <li><strong>Search Details: </strong>We have the information about your search details. For example, if you search for Candidates in terms of experience, we track all the details.</li>
            </ul>
            <h3>How Do We Use This Obtained Information?</h3>
            <p>We use the information we collect about you and Candidates to improve the quality of our products and search behaviors. However, we do not collect data when you are not using our product. Here are the ways we use the information we have about you:</p>
            <ul>
                <li><strong>We provide, customize, and improve our products: </strong>We use collected information to customize content and features, deliver our products, and make suggestions for you (Candidates you might interest in). We customize our products for you that are unique and pertinent. For the creation, we use your connections, preferences, activities, and interests from the data we collect from you and others. We also keep an account of how you use and interact with our products.</li>
                <li><strong>We list you on search results based on information: </strong>We use the information that we collect about you and list you in search results for Candidate searches based on such information. For example, when a Candidate searches for a job vacancy, we provide them access to the information we have about you.</li>
                <li><strong>Customize the candidate’s search experience: </strong>We use the information we have about Candidates to customize their search experience. We also use their previous search behaviors to improve current search results. For example, If a Candidate searches for an IT agency multiple times, we automatically display the names.</li>
            </ul>
            <h3>Promote Safety, Probity, and Reliability</h3>
            <p>We use the information we have and verify accounts, discern and avert spam and harmful conduct. We are focused on maintaining the integrity of our products. We ensure reliability on and off of the product. We use your data to look over if there is any suspicious activity or violations of our policy.</p>
            <h3>Using your information, we communicate with you</h3>
            <p>When you contact us, we respond with the use of the details we have about you. We also use your information to send you details about our products and let you know our policies and terms.</p>
            <h3>How do we share your information with others?</h3>
            <p>We provide the information we have to Companies who are looking for suitable Candidates.</p>
            <ul>
                <li><strong>Public Information: </strong>Companies can see your public details like your profile picture, the date you subscribed to our product, and the Companies that shortlisted you. However, Companies need to purchase information about your email and phone number. Once they get the details, they will be able to view the contact information for two weeks.</li>
                <li><strong>Your Active Status And Presence On Our Products: </strong>Companies can see your active status or the last time of your activation through signals. If you do not use our platform for about a month you will not appear in search results. You will not appear in search results if you update your profile as not available.</li>
                <li><strong>Your choices and obligations: </strong>We provide you the freedom to update your information based on your interests. You can choose to update such information if you want or leave the space vacant. However, there are a few pieces of data you should update on the website.</li>
                <li><strong>Shortlist or select Candidates: </strong>As a Company, you have the right to choose whether to shortlist any Candidate or add him/her to your cart.</li>
                <li><strong>Access and control your personal information: </strong>We allow you to share, delete, or change your personal information whenever you wish to. We offer you control over your information.</li>
                <li><strong>Account closure: </strong>You have the freedom to update your information but you cannot delete your account. You can however update your status as not available.</li>
            </ul>
        </div></div></div></section></div>
    )
}
export default PrivacyPolicyContents;