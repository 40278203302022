import React from "react";
import PrivacyPolicyContents from "../../pages-sections/privacyPolicyContents";
import companyMetaImage from "../../images/privacy_meta.jpg";
import NewPageLayout from "../../components/layout/new-page-layout";

const PrivacyPolicy = () => {
    return (
        <NewPageLayout
            isCandidate={false} 
            title="Privacy Policy - WDN"
            description="WDN respects the privacy of its valued customers and protects personal information."
            seoImage={companyMetaImage}
        >
            <PrivacyPolicyContents />
        </NewPageLayout>
    )
}
export default PrivacyPolicy;